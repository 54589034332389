const Spinner = () => {
    return (
        <div className="flex mt-[50px] justify-center items-center">
            <div>
                <div className="animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-blue-600" role="status"/>
            </div>
        </div>  
    )
}

export default Spinner;