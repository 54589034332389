import Script from "next/script";
import Link from "next/link";
import Head from "next/head";

import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";

const Login = () => {
  const router = useRouter();
  const { signInWithGoogle, logInWithEmailAndPassword, onEmailVerification } =
    useContext(FirebaseContext);

  const [loginId, setLoginId] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onGoogleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const userData = await signInWithGoogle();
    if (userData) {
      const user = userData.user;
      if (user.emailVerified && user.displayName) {
        if (router.query.salesboardId) {
          router.push("/salesboard/" + router.query.salesboardId);
        } else {
          await router.push("/dashboard");
        }
        setIsLoading(false);
      } else if (user.emailVerified && !user.displayName) {
        await router.push("/signup/info");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const onEmailSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const userData = await logInWithEmailAndPassword(loginId, loginPassword);
    if (userData) {
      const user = userData.user;
      if (user.emailVerified && user.displayName) {
        if (router.query.salesboardId) {
          router.push("/salesboard/" + router.query.salesboardId);
        } else {
          await router.push("/dashboard");
        }
        setIsLoading(false);
      } else if (user.emailVerified && !user.displayName) {
        await router.push("/signup/info");
        setIsLoading(false);
      } else {
        await router.push("/signup/emailconfirm");
        emailResend();
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const emailResend = async () => {
    await onEmailVerification();
  };

  const pushToPasswordResetPage = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await router.push("/login/identify");
    setIsLoading(false);
  };

  return (
    <div className="">
      <Navbar />
      <div className="flex justify-center">
        {/* <Script src="/static/ChannelTalk.js" /> */}
        <Head>
          <title>ocena | 로그인</title>
          <link rel="icon" href="/assets/favicon.ico" />
        </Head>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="mx-4 mb-10 flex w-full max-w-sm flex-col items-center font-kr">
            <div className="mt-10 flex w-full justify-center md:mt-14">
              {/* <img src="/assets/ocena_logo.png" className=" h-7" alt="logo" /> */}
              <h1 className="mt-3 text-4xl font-bold text-zinc-700">로그인</h1>
            </div>
            <button
              onClick={onGoogleSignIn}
              className="my-9 flex w-[85%] items-center justify-center space-x-3 rounded-full bg-zinc-100 py-4 font-medium text-zinc-600 transition-all hover:translate-y-[-2px] hover:shadow-md hover:shadow-zinc-300"
            >
              <span>
                <img src="/assets/google_icon.png" className="h-5" alt="logo" />
              </span>
              <span>구글 로그인</span>
            </button>
            <div className="w-full border-b-[1px] border-zinc-500"></div>
            <form
              className="flex w-[95%] grow flex-col"
              onSubmit={onEmailSignIn}
            >
              <label className="text-md mt-10 flex flex-col font-medium text-zinc-600">
                이메일 주소
                <input
                  className="mt-2 border-b border-zinc-300 py-2 px-1 text-base font-normal placeholder:text-zinc-400/80 focus:border-b-[1.5px] focus:border-zinc-600 focus:outline-none"
                  type="text"
                  value={loginId}
                  placeholder={"ocena@example.com"}
                  maxLength={45}
                  required
                  onChange={(e) => {
                    setLoginId(e.target.value);
                  }}
                />
              </label>
              <label className="text-md mt-6 flex flex-col font-medium text-zinc-600">
                비밀번호
                <input
                  className="mt-2 border-b border-zinc-300 py-2 px-1 text-base font-normal placeholder:text-zinc-400/80 focus:border-b-[1.5px] focus:border-zinc-600 focus:outline-none"
                  type="password"
                  value={loginPassword}
                  placeholder={"******"}
                  minLength={6}
                  maxLength={45}
                  required
                  onChange={(e) => {
                    setLoginPassword(e.target.value);
                  }}
                />
              </label>
              <button className="mt-8 w-full self-center rounded-xl bg-primary py-3 font-kr text-sm text-white transition-all hover:bg-primary/80">
                로그인
              </button>
            </form>
            <button
              onClick={pushToPasswordResetPage}
              className="mt-4 self-center rounded-xl py-3 px-6 font-kr text-sm text-primary hover:text-primary/60"
            >
              비밀번호 찾기
            </button>
            <div className="mt-10 flex w-full flex-col items-center justify-between space-y-3">
              <p className=" w-full text-center text-sm font-medium text-zinc-500">
                계정이 없으신가요?{" "}
              </p>

              <Link href="/launch">
                <button className="self-center whitespace-nowrap rounded-full border-2 border-zinc-600 bg-white px-6 py-5 font-kr text-sm font-bold text-zinc-600 transition-all hover:-translate-y-1 hover:shadow-md focus:translate-y-0 focus:shadow-none">
                  정식 출시 사전신청
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
